import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import { Loader } from "src/components/layout/Loader"

const Home = lazy<FC>(() => import("./home"))

const routes: RouteObject[] = [
  {
    Component: Home,
    loader: () => <Loader spinning />,
    path: "",
    index: true,
  },
  {
    Component: Outlet,
    path: "*",
  },
]

export default routes
